import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const HcvRollUpperTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "機械名", value: "machineCode" },
  { text: "作業者1", value: "userId1" },
  { text: "作業者2", value: "userId2" },
  { text: "昼勤/夜勤", value: "shift" },
  { text: "受注番号", value: "acceptNo" },
  { text: "受注番号品 完了", value: "successFalg" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "総数量(m)", value: "totalQuantity" },
  { text: "枝番", value: "qdBranchNo1" },
  { text: "数量明細(m)", value: "quantityDetails1" },
  { text: "枝番", value: "qdBranchNo2" },
  { text: "数量明細(m)", value: "quantityDetails2" },
  { text: "枝番", value: "qdBranchNo3" },
  { text: "数量明細(m)", value: "quantityDetails3" },
  { text: "枝番", value: "qdBranchNo4" },
  { text: "数量明細(m)", value: "quantityDetails4" },
  { text: "枝番", value: "qdBranchNo5" },
  { text: "数量明細(m)", value: "quantityDetails5" },
  { text: "押出外径(mm)", value: "pushOuterDiameter" },
  { text: "シース厚さ(mm)規格", value: "sheathThicknessStandard" },
  { text: "シース厚さ(mm)最小", value: "sheathThicknessMin" },
  { text: "被覆材料", value: "coatingMaterial" },
  { text: "ロットNo.1", value: "lotNo1" },
  { text: "ロットNo.2", value: "lotNo2" },
  { text: "ロットNo.3", value: "lotNo3" },
  { text: "使用導体・撚線(m)", value: "conductorStrandedWire1" },
  { text: "枝番", value: "cswBranchNo1" },
  { text: "使用導体・撚線(m)", value: "conductorStrandedWire2" },
  { text: "枝番", value: "cswBranchNo2" },
  { text: "偏肉", value: "thicknessDeviation" },
  { text: "表面表示", value: "surfaceIndication" },
  { text: "製品異常処置報告（修理・廃棄・解体）", value: "abnormality" },
  { text: "梱包/枝番", value: "packingBranchNo" },
  { text: "備考", value: "remarks" }
] as const;

export class HcvRollUpperTable {
  workDate: string;
  updateTs: string | null;
  machineCode: string | null;
  userId1: string | null;
  userId2: string | null;
  shift: string | null;
  acceptNo: string | null;
  successFalg: string | null;
  itemName: string | null;
  size: string | null;
  totalQuantity: number | null;
  qdBranchNo1: string | null;
  qdBranchNo2: string | null;
  qdBranchNo3: string | null;
  qdBranchNo4: string | null;
  qdBranchNo5: string | null;
  quantityDetails1: string | null;
  quantityDetails2: string | null;
  quantityDetails3: string | null;
  quantityDetails4: string | null;
  quantityDetails5: string | null;
  pushOuterDiameter: number | null;
  sheathThicknessStandard: number | null;
  sheathThicknessMin: number | null;
  coatingMaterial: string | null;
  lotNo1: string | null;
  lotNo2: string | null;
  lotNo3: string | null;
  conductorStrandedWire1: number | null;
  conductorStrandedWire2: number | null;
  cswBranchNo1: string | null;
  cswBranchNo2: string | null;
  thicknessDeviation: string | null;
  surfaceIndication: string | null;
  abnormality: string | null;
  packingBranchNo: string | null;
  remarks: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    machineCode: string | null;
    userId1: string | null;
    userId2: string | null;
    shift: string | null;
    acceptNo: string | null;
    successFalg: string | null;
    itemName: string | null;
    size: string | null;
    totalQuantity: number | null;
    qdBranchNo1: string | null;
    qdBranchNo2: string | null;
    qdBranchNo3: string | null;
    qdBranchNo4: string | null;
    qdBranchNo5: string | null;
    quantityDetails1: string | null;
    quantityDetails2: string | null;
    quantityDetails3: string | null;
    quantityDetails4: string | null;
    quantityDetails5: string | null;
    pushOuterDiameter: number | null;
    sheathThicknessStandard: number | null;
    sheathThicknessMin: number | null;
    coatingMaterial: string | null;
    lotNo1: string | null;
    lotNo2: string | null;
    lotNo3: string | null;
    conductorStrandedWire1: number | null;
    conductorStrandedWire2: number | null;
    cswBranchNo1: string | null;
    cswBranchNo2: string | null;
    thicknessDeviation: string | null;
    surfaceIndication: string | null;
    abnormality: string | null;
    packingBranchNo: string | null;
    remarks: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.machineCode = props.machineCode;
    this.userId1 = props.userId1;
    this.userId2 = props.userId2;
    this.shift = props.shift;
    this.acceptNo = props.acceptNo;
    this.successFalg = props.successFalg;
    this.itemName = props.itemName;
    this.size = props.size;
    this.totalQuantity = props.totalQuantity;
    this.qdBranchNo1 = props.qdBranchNo1;
    this.qdBranchNo2 = props.qdBranchNo2;
    this.qdBranchNo3 = props.qdBranchNo3;
    this.qdBranchNo4 = props.qdBranchNo4;
    this.qdBranchNo5 = props.qdBranchNo5;
    this.quantityDetails1 = props.quantityDetails1;
    this.quantityDetails2 = props.quantityDetails2;
    this.quantityDetails3 = props.quantityDetails3;
    this.quantityDetails4 = props.quantityDetails4;
    this.quantityDetails5 = props.quantityDetails5;
    this.pushOuterDiameter = props.pushOuterDiameter;
    this.sheathThicknessStandard = props.sheathThicknessStandard;
    this.sheathThicknessMin = props.sheathThicknessMin;
    this.coatingMaterial = props.coatingMaterial;
    this.lotNo1 = props.lotNo1;
    this.lotNo2 = props.lotNo2;
    this.lotNo3 = props.lotNo3;
    this.conductorStrandedWire1 = props.conductorStrandedWire1;
    this.conductorStrandedWire2 = props.conductorStrandedWire2;
    this.cswBranchNo1 = props.cswBranchNo1;
    this.cswBranchNo2 = props.cswBranchNo2;
    this.thicknessDeviation = props.thicknessDeviation;
    this.surfaceIndication = props.surfaceIndication;
    this.abnormality = props.abnormality;
    this.packingBranchNo = props.packingBranchNo;
    this.remarks = props.remarks;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const HcvRollUpperText = {
  workDate: { text: "作業年月日", value: "workDate" },
  machineCode: { text: "機械名", value: "machineCode" },
  userId1: { text: "作業者1", value: "userId1" },
  userId2: { text: "作業者2", value: "userId2" },
  shift: { text: "昼勤/夜勤", value: "shift" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  successFalg: { text: "受注番号品 完了", value: "successFalg" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  totalQuantity: { text: "総数量(m)", value: "totalQuantity" },
  qdBranchNo1: { text: "枝番", value: "qdBranchNo1" },
  qdBranchNo2: { text: "枝番", value: "qdBranchNo2" },
  qdBranchNo3: { text: "枝番", value: "qdBranchNo3" },
  qdBranchNo4: { text: "枝番", value: "qdBranchNo4" },
  qdBranchNo5: { text: "枝番", value: "qdBranchNo5" },
  quantityDetails1: { text: "数量明細(m)", value: "quantityDetails1" },
  quantityDetails2: { text: "数量明細(m)", value: "quantityDetails2" },
  quantityDetails3: { text: "数量明細(m)", value: "quantityDetails3" },
  quantityDetails4: { text: "数量明細(m)", value: "quantityDetails4" },
  quantityDetails5: { text: "数量明細(m)", value: "quantityDetails5" },
  pushOuterDiameter: { text: "押出外径(mm)", value: "pushOuterDiameter" },
  sheathThicknessStandard: {
    text: "シース厚さ(mm)規格",
    value: "sheathThicknessStandard"
  },
  sheathThicknessMin: {
    text: "シース厚さ(mm)最小",
    value: "sheathThicknessMin"
  },
  coatingMaterial: { text: "被覆材料", value: "coatingMaterial" },
  lotNo1: { text: "ロットNo.1", value: "lotNo1" },
  lotNo2: { text: "ロットNo.2", value: "lotNo2" },
  lotNo3: { text: "ロットNo.3", value: "lotNo3" },
  conductorStrandedWire1: {
    text: "使用導体・撚線(m)",
    value: "conductorStrandedWire1"
  },
  conductorStrandedWire2: {
    text: "使用導体・撚線(m)",
    value: "conductorStrandedWire2"
  },
  cswBranchNo1: { text: "枝番", value: "cswBranchNo1" },
  cswBranchNo2: { text: "枝番", value: "cswBranchNo2" },
  thicknessDeviation: { text: "偏肉", value: "thicknessDeviation" },
  surfaceIndication: { text: "表面表示", value: "surfaceIndication" },
  abnormality: {
    text: "製品異常処置報告（修理・廃棄・解体）",
    value: "abnormality"
  },
  packingBranchNo: { text: "梱包/枝番", value: "packingBranchNo" },
  remarks: { text: "備考", value: "remarks" }
} as const;

export class HcvRollUpper extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  userId1: string | null;
  userId2: string | null;
  shift: string | null;
  acceptNo: string | null;
  successFalg: string | null;
  itemName: string | null;
  size: string | null;
  totalQuantity: number | null;
  qdBranchNo1: string | null;
  qdBranchNo2: string | null;
  qdBranchNo3: string | null;
  qdBranchNo4: string | null;
  qdBranchNo5: string | null;
  quantityDetails1: string | null;
  quantityDetails2: string | null;
  quantityDetails3: string | null;
  quantityDetails4: string | null;
  quantityDetails5: string | null;
  pushOuterDiameter: number | null;
  sheathThicknessStandard: number | null;
  sheathThicknessMin: number | null;
  coatingMaterial: string | null;
  lotNo1: string | null;
  lotNo2: string | null;
  lotNo3: string | null;
  conductorStrandedWire1: number | null;
  conductorStrandedWire2: number | null;
  cswBranchNo1: string | null;
  cswBranchNo2: string | null;
  thicknessDeviation: string | null;
  surfaceIndication: string | null;
  abnormality: string | null;
  packingBranchNo: string | null;
  remarks: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: HcvRollUpper);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: HcvRollUpper | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof HcvRollUpper) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.userId1 = _props.userId1;
      this.userId2 = _props.userId2;
      this.shift = _props.shift;
      this.acceptNo = _props.acceptNo;
      this.successFalg = _props.successFalg;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.totalQuantity = _props.totalQuantity;
      this.qdBranchNo1 = _props.qdBranchNo1;
      this.qdBranchNo2 = _props.qdBranchNo2;
      this.qdBranchNo3 = _props.qdBranchNo3;
      this.qdBranchNo4 = _props.qdBranchNo4;
      this.qdBranchNo5 = _props.qdBranchNo5;
      this.quantityDetails1 = _props.quantityDetails1;
      this.quantityDetails2 = _props.quantityDetails2;
      this.quantityDetails3 = _props.quantityDetails3;
      this.quantityDetails4 = _props.quantityDetails4;
      this.quantityDetails5 = _props.quantityDetails5;
      this.pushOuterDiameter = _props.pushOuterDiameter;
      this.sheathThicknessStandard = _props.sheathThicknessStandard;
      this.sheathThicknessMin = _props.sheathThicknessMin;
      this.coatingMaterial = _props.coatingMaterial;
      this.lotNo1 = _props.lotNo1;
      this.lotNo2 = _props.lotNo2;
      this.lotNo3 = _props.lotNo3;
      this.conductorStrandedWire1 = _props.conductorStrandedWire1;
      this.conductorStrandedWire2 = _props.conductorStrandedWire2;
      this.cswBranchNo1 = _props.cswBranchNo1;
      this.cswBranchNo2 = _props.cswBranchNo2;
      this.thicknessDeviation = _props.thicknessDeviation;
      this.surfaceIndication = _props.surfaceIndication;
      this.abnormality = _props.abnormality;
      this.packingBranchNo = _props.packingBranchNo;
      this.remarks = _props.remarks;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "HRUPPE";
    this.machineCode = null;
    this.userId1 = null;
    this.userId2 = null;
    this.shift = "0";
    this.acceptNo = null;
    this.successFalg = "0";
    this.itemName = null;
    this.size = null;
    this.totalQuantity = null;
    this.qdBranchNo1 = null;
    this.qdBranchNo2 = null;
    this.qdBranchNo3 = null;
    this.qdBranchNo4 = null;
    this.qdBranchNo5 = null;
    this.quantityDetails1 = null;
    this.quantityDetails2 = null;
    this.quantityDetails3 = null;
    this.quantityDetails4 = null;
    this.quantityDetails5 = null;
    this.pushOuterDiameter = null;
    this.sheathThicknessStandard = null;
    this.sheathThicknessMin = null;
    this.coatingMaterial = null;
    this.lotNo1 = null;
    this.lotNo2 = null;
    this.lotNo3 = null;
    this.conductorStrandedWire1 = null;
    this.conductorStrandedWire2 = null;
    this.cswBranchNo1 = null;
    this.cswBranchNo2 = null;
    this.thicknessDeviation = "0";
    this.surfaceIndication = "0";
    this.abnormality = null;
    this.packingBranchNo = null;
    this.remarks = null;
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
